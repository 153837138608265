import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import Typist from "react-typist";
import {
    Backdrop,
    Button,
    CircularProgress, CssBaseline,
    Fab, Link,
    Typography
} from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import moment from "moment/moment";
import ReactFloaterJs from 'react-floaterjs'

const generateRandom = (max: number, failOn: number[]): number => {
    const num = Math.floor(Math.random() * max);
    return failOn.includes(num) ? generateRandom(max, failOn) : num;
}

const songs = [
    {
        file: 'tears.mp4',
        betterFit: true,
        sound: 0.3,
        start: 1,
    },
    {
        file: 'enemy.mp4',
        betterFit: true,
        sound: 0.35,
        start: 0,
    },
    /*{
        file: 'rollinginthedeep.mp4',
        betterFit: false,
        sound: 0.7,
        start: 1,
    },*/
    {
        file: 'lights.mp4',
        betterFit: true,
        sound: 0.3,
        start: 20,
    },
    {
        file: 'stars.mp4',
        betterFit: true,
        sound: 0.6,
        start: 0,
    },
    {
        file: 'circles.mp4',
        betterFit: true,
        sound: 0.25,
        start: 5,
    }
];

const App = () => {
    const diffDays = moment().diff('2003-08-03', 'years');

    const sentences = [
        `${diffDays} years old`,
        'I like MaterialUI',
        'Disney fan',
        'Apple Sheep 🍎🐑',
        'Java Master ☕',
        'PHP Developer',
        'Kotlin Developer',
        'JS Developer',
        'GitLab is better!',
        'I like React',
        "OVHCloud is my go-to",
        'RIP Tony Stark',
        'I like DDoS analysis',
        'C# Developer'
    ];

    const first = generateRandom(sentences.length, []);

    const [ muted, setMuted ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ sentenceKey, setSentenceKey ] = useState(first);
    const [ bl, setBl ] = useState([ first ]);
    const [ overlay, setOverlay ] = useState(false);
    const [ scroll, setScroll ] = useState(0);

    const [ song ] = useState(songs[Math.floor(Math.random() * songs.length)]);

    const handleClick = () => {
        const video = document.getElementById('backgroundVideo');

        setMuted(false);

        // @ts-ignore
        video.currentTime = song.start;

        setOverlay(false);
    }

    const onVideoLoad = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        setLoading(false);

        e.currentTarget.volume = song.sound;
        e.currentTarget.currentTime = song.start;
        e.currentTarget.play()
            .catch(() => {
                setMuted(true);
                setOverlay(true);
                const video = document.getElementById('backgroundVideo');

                // @ts-ignore
                video.play();
            });
    }

    const handleScroll = useCallback((e: WheelEvent) => {
        if (overlay) return;

        const up = e.deltaY >= 0;
        const newScroll = up ? scroll + 50 : scroll - 50;

        console.log(up);
        console.log(newScroll)

        if (newScroll < 0) {
            setScroll(0);
            return;
        }

        if (newScroll > document.getElementById('aboutMe')!.clientHeight) {
            setScroll(document.getElementById('aboutMe')!.clientHeight)
            return;
        }

        setScroll(newScroll);
    }, [ overlay, scroll ])

    const handleProjectsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setScroll(document.getElementById('aboutMe')!.clientHeight);
    }

    useEffect(() => {
        window.addEventListener("wheel", handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll)
        }
    }, [ handleScroll, scroll]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (bl.length === sentences.length) {
                const newSentence = generateRandom(sentences.length, []);
                setBl([ newSentence ]);
                setSentenceKey(newSentence);
                return;
            }

            const newSentence = generateRandom(sentences.length, bl);
            setSentenceKey(newSentence);
            bl.push(newSentence)
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [ bl, sentenceKey, sentences.length ])

    return (
        <>
            <CssBaseline/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2, backdropFilter: 'blur(60px)' }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(60px)', flexDirection: 'column' }}
                open={overlay}
            >
                <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 200, textAlign: 'center' }}>
                    <Typist cursor={{ show: false }}>
                        Hi, welcome! Please satisfy this <Link href={'https://developer.chrome.com/blog/autoplay/'}>beautiful policy</Link> and interact with the website before continuing...
                    </Typist>
                </Typography>
                <Fab variant="extended" onClick={handleClick}>
                    <PublicIcon sx={{ mr: 1 }} />
                    <span style={{ marginTop: 1 }}>Make your browser happy</span>
                </Fab>
            </Backdrop>
            <section id='main' className="showcase" onClick={() => setScroll(0)}>
                <video
                    id={'backgroundVideo'}
                    src={`./videos/${song.file}`}
                    loop
                    muted={muted}
                    style={{ marginTop: song.betterFit ? '-20vh' : undefined, height: song.betterFit ? '150%' : undefined }}
                    onLoadedData={onVideoLoad}
                />
                <div className="overlay"/>
                <div className="text">
                    <h2>OpenSource</h2>
                    <h3>
                        <Typist key={sentenceKey} avgTypingDelay={55}>
                            {sentences[sentenceKey]}
                        </Typist>
                    </h3>
                    <p>
                    </p>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        size={'large'}
                        sx={{ backgroundColor: 'whitesmoke', color: 'black' }}
                        onClick={handleProjectsClick}
                    >
                        View Projects
                    </Button>
                </div>
                <ul className="social">
                    <li><a href="https://www.facebook.com/dejan.zegarac.8/"><img src="https://i.ibb.co/x7P24fL/facebook.png" alt={''}/></a></li>
                    <li><a href="https://www.instagram.com/dejanzegarac/"><img src="https://i.ibb.co/ySwtH4B/instagram.png" alt={''}/></a></li>
                </ul>
            </section>
            <section id='aboutMe' style={{ marginTop: -scroll }}>
                <div className={'avatarContainer'}>
                    <ReactFloaterJs>
                        <img
                            id={'avatar'}
                            src={'./imgs/avatar.webp'}
                            alt={'Could not be loaded'}
                        />
                    </ReactFloaterJs>
                    <Typography variant={'h4'} gutterBottom>The name's Žegarac, Dejan Žegarac</Typography>
                    <Typography variant={'h6'} gutterBottom>
                        I'm a fast-learning java, kotlin, C# and backend web developer from <img className={'flag'} src={'./imgs/serbia.png'} alt={'Could not load'}/> Serbia.
                    </Typography>

                    <div className={'flex-row'}>
                        <Button variant={'outlined'} onClick={() => window.location.href = 'https://www.spigotmc.org/members/179778/'}>Visit my SpigotMC Profile</Button>
                        <Button variant={'outlined'} onClick={() => window.location.href = 'https://www.mc-market.org/members/65677/'}>Visit my MC-Market Profile</Button>
                    </div>

                    <Typography variant={'h4'} gutterBottom component={'h5'}>My projects</Typography>
                    <div className={'flex-row weight-thin transformUp'}>
                        <a href={'https://www.spigotmc.org/resources/82268/'}>
                            <img
                                src={'./imgs/playerservers.png'}
                                alt={'Could not be loaded'}
                            />
                            <Typography variant={'h5'} component={'h1'}>PlayerServers</Typography>
                        </a>
                        <a href={'https://deploysflow.cloud'}>
                            <img
                                src={'./imgs/deploysflow.png'}
                                alt={'Could not be loaded'}
                            />
                            <Typography variant={'h5'} component={'h1'}>DeploysFlow</Typography>
                        </a>
                        <a href={'https://thearcadia.xyz'}>
                            <img
                                src={'./imgs/arcadia.png'}
                                alt={'Could not be loaded'}
                            />
                            <Typography variant={'h5'} component={'h1'}>Arcadia Development</Typography>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default App;
